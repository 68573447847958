import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layouts'
import PostSummary from '../components/blog/PostSummary'
import favicon from '../images/favicon.svg'
import Container from '../components/parts/Container'

export const query = graphql`
  query TagsPageQuery(
    $limit: Int,
    $skip: Int,
    $tag: String,
  ) {
    allPrismicPost(
      limit: $limit,
      skip: $skip,
      sort: { fields: data___date, order: DESC },
      filter: { tags: { in: [$tag] } }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        perPage
        pageCount
        itemCount
        hasPreviousPage
        totalCount
      }
      edges {
        node {
          id
          uid
          type
          tags
          data {
            title {
              richText
            }
            date
            body {
              ... on PrismicPostDataBodyText {
                id
                slice_label
                slice_type
                primary {
                  text {
                    richText
                  }
                }
              }
              ... on PrismicPostDataBodyMarkdown {
                id
                slice_label
                slice_type
                primary {
                  markdown {
                    richText
                  }
                }
              }
              ... on PrismicPostDataBodyHtml {
                id
                slice_label
                slice_type
                primary {
                  html
                }
              }
              # ... on PrismicPostDataBodyEmbed {
              #   id
              #   slice_label
              #   slice_type
              #   primary {
              #     url {
              #       embed_url
              #     }
              #   }
              # }
            }
          }
        }
      }
    }
  }
`

const Paginate = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;

  a {
    display: inline-block;
    padding: .8em 1em;
    color: #666;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;

    &:hover {
      color: #aaa;
    }
  }
`

const Paginator = ({ pageInfo }) => {
  if (!pageInfo) return null

  return (
    <Paginate>
      {
        pageInfo.hasPreviousPage
          ? <Link to={`/posts/${pageInfo.currentPage - 1}`}>← Prev</Link>
          : null
      }
      {
        pageInfo.hasNextPage
          ? <Link to={`/posts/${pageInfo.currentPage + 1}`}>Next →</Link>
          : null
      }
    </Paginate>
  )
}

const Posts = ({ posts }) => {
  if (!posts) return null

  return posts.map((post) => <PostSummary post={post} key={post.node.id} />)
}

const TagPageHead = styled.div`
  display: inline-block;
  margin-left: 0.8em;
  font-weight: bold;

  ::before {
    content: '#';
    display: inline-block;
  }
`

const TagHeaderNav = styled(Container)`
  display: flex;
  margin-bottom: 30px;

  > a {
    border: 0;
    line-height: 0;
    text-decoration: none;
  }
`

const TagsPage = ({ data, pageContext }) => {
  if (!data) return null

  const { edges, pageInfo } = data.allPrismicPost
  const { tag } = pageContext

  const title = `#${tag}`

  return (
    <Layout noIndex title={title}>
      <TagHeaderNav>
        <Link to="/posts/1">
          <img src={favicon} width="20" height="20" alt="logo" />
        </Link>
        <TagPageHead>{ tag }</TagPageHead>
      </TagHeaderNav>
      <Posts posts={edges} />
      <Paginator pageInfo={pageInfo} />
    </Layout>
  )
}

export default TagsPage
