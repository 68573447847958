import React from 'react'
import PostSection from './PostSection'

const PostSummary = ({ post }) => {
  const url = `/post/${post.node.uid}`

  return <PostSection data={post.node.data} tags={post.node.tags} titleUrl={url} />
}

export default PostSummary
